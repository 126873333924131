import { render, staticRenderFns } from "./ContributorsPageBuilder.vue?vue&type=template&id=5072b846&scoped=true&"
import script from "./ContributorsPageBuilder.vue?vue&type=script&lang=js&"
export * from "./ContributorsPageBuilder.vue?vue&type=script&lang=js&"
import style0 from "./ContributorsPageBuilder.vue?vue&type=style&index=0&id=5072b846&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5072b846",
  null
  
)

component.options.__file = "ContributorsPageBuilder.vue"
export default component.exports